input[type="radio"] {
  display: none;
}

label {
  cursor: pointer;
}

.black-font {
  color: black !important;
}

.main-container {
  display: flex;
  width: 108px;
  height: 30px;
  background-color: #7dc1b5;
  position: relative;
  // clip-path: polygon(0 0, 85% 0, 100% 25%, 100% 100%, 15% 100%, 0 70%);
  box-shadow: 1px 1px 10px 1px rgba(0, 0, 0, 0.5);
  margin-left: 5px;
}

.main-container input[value="left"] {
  background-color: red;
}

.switch {
  height: 100%;
  width: 36px;
  background-color: white;
  position: absolute;
  transition: ease-in-out;
  // clip-path:   polygon(0 0, 60% 0, 100% 25%, 100% 100%, 45% 100%, 0 70%);
  z-index: 2;
}

.icon-switch {
  z-index: 4;
}

@media screen and (max-width: 768px) {
  .main-container {
    height: 20px;
    font-size:15px;
  }
 }

/*---------------- LEFT ------------------- */
.switch.left-to-center {
  animation-name: leftToCenter;
  animation-duration: 0.5s;
}


.switch.left-to-right {
  animation-name: leftToRight;
  animation-duration: 0.5s;
}

.left-label {
  position: absolute;
}

#left + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33%;
}

// #left + label > .icon-switch {
//   display: none;
// }

#left:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33%;
}

// #left:checked + label > .icon-switch {
//   display: none;
// }

@keyframes leftToCenter {
  from {
    left: 0%;
  }
  to {
    left: 33%;
  }
}

@keyframes leftToRight {
  from {
    left: 0%;
  }
  to {
    left: 66%;
  }
}

/* -------------------- center ------------------ */
.switch.center-to-left {
  animation-name: centerToLeft;
  animation-duration: 0.5s;
}

.switch.center-to-right {
  animation-name: centerToRight;
  animation-duration: 0.5s;
}

.switch.center-position {
  left: 33%;
}

.center-label {
  position: absolute;
  left: 33%;
}

#center + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33%;
}

#center:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33%;
}

@keyframes centerToLeft {
  from {
    left: 33%;
  }
  to {
    left: 0%;
  }
}

@keyframes centerToRight {
  from {
    left: 33%;
  }
  to {
    right: 66%;
  }
}

/* ------------------- RIGHT ------------------- */
.switch.right-to-left {
  animation-name: rightToLeft;
  animation-duration: 0.5s;
}

.switch.right-to-center {
  animation-name: rightToCenter;
  animation-duration: 0.5s;
}

.switch.right-position {
  left: 66%;
}

.right-label {
  position: absolute;
  right: 2px;
}

.icon-switch {
  z-index: 20;
}

#right + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33%;
}

#right:checked + label {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 33%;
}

// #right:checked + label > .icon-switch {
//   display: none;
// }

@keyframes rightToLeft {
  from {
    left: 66%;
  }
  to {
    left: 0%;
  }
}

@keyframes rightToCenter {
  from {
    left: 66%;
  }
  to {
    left: 33%;
  }
}
