a.button {
  font-family: Chakra Petch;
  font-style: normal;
  font-weight: 800;
  font-size: 27px !important;
  line-height: 32px !important;
  text-transform: uppercase;
  text-decoration: none;
  text-align: center;
  color: #ffffff;
  background-color: #14c2a3;
  border-radius: 5px;
  padding: 14px 50px;
  font-size: 28px;
  line-height: 28px;
  transition: all 0.7s ease-in-out;
}

a.button.login {
  font-size: 12px !important;
  line-height: 32px !important;
  background-color: #eb3d5c;
  padding: 14px 20px;
  font-size: 28px;
  line-height: 28px;
}

a.button.logout {
  font-size: 12px !important;
  line-height: 32px !important;
  background-color: #14c2a3;
  padding: 14px 20px;
  font-size: 28px;
  line-height: 28px;
}

a.button:hover {
  background-color: #14c2a3;
  color: #ffffff;
  transition: all 0.7s ease-in-out;
}

.disabled-link {
  pointer-events: none;
  opacity: 0.1;
}

@media all and (max-width: 768px) {
  a.button {
    padding: 12px 50px !important;
    font-size: 20px !important;
  }
}

// @media all and (max-width: 992px) {
//   a.button {
//     padding: 15px 40px;
//   }
// }
