@use "sass:math";

$custom-table-color: #f0f0f0;
$bg-dark: #292929;
$bg-light: #1e1e1e;
$bg-hover: #333;
$border-color: #444;
$border-focus: #14c2a3;

.custom-table {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  font-family: "Archivo", sans-serif;
  font-size: 16px;
  color: $custom-table-color;
  // margin-top: 7px;
  overflow-x: auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(1, 24, 27, 0.3) 25%,
    rgba(1, 24, 27, 0.3) 75%,
    rgba(0, 0, 0, 0.3) 100%
  );

  table-layout: fixed;
  // padding: 12px;
  // margin-right: 12px;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
}

.rank {
  font-family: Chakra Petch !important;
  font-size: 16px !important;
  color: white;
  height: 30px;
  margin-left: 10px;
  width: 30px;
  cursor: pointer !important;
}

.rank:hover {
  color: white;
}

.rank-icon {
  font-size: 20px !important;
  margin-left: 10px;
}

.custom-table thead {
  background-color: #015949;
  font-family: Chakra Petch;
  border: 1px solid $border-color;
  cursor: pointer;
}

.custom-table thead th {
  padding: 10px;
  text-align: center;
}

.custom-table thead th:nth-child(2) {
  cursor: default;
}

.custom-table thead th {
  .first {
    padding: 10px;
    text-align: center;
  }
}

.custom-table tbody tr {
  transition: background-color 0.5s;
  cursor: default;
  height: 130px;
}

.custom-table tbody tr:hover {
  background-color: #0d8f77;
  opacity: 0.8;
}

.custom-table tbody td {
  padding: 5px;
}

.custom-table tbody td span {
  padding: 5px;
  text-align: center;
}

.filter-input {
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $border-color;
  color: #eb3d5c;
  padding: 10px;
  outline: none;
  font-size: 16px;
  font-style: italic;
  width: 250px;
  text-align: center;
  font-family: Chakra Petch;
  caret-color: #eb3d5c;
}

//--custom table mobile

.custom-table-mobile {
  border-collapse: collapse;
  width: 100%;
  max-width: 100%;
  font-family: Chakra Petch, sans-serif;
  color: $custom-table-color;
  margin-top: 50px;
  overflow-x: auto;
  background: rgb(0, 0, 0);
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0.3) 0%,
    rgba(1, 24, 27, 0.3) 25%,
    rgba(1, 24, 27, 0.3) 75%,
    rgba(0, 0, 0, 0.3) 100%
  );
  table-layout: fixed;
  padding: 12px;
  margin-right: 12px;
  font-size: 10px;
  box-shadow: 0 0 30px rgba(255, 255, 255, 0.2);
}

.custom-table-mobile thead {
  background-color: #14c2a3;
}

.custom-table-mobile thead th {
  padding: 5px;
  text-align: center;
  height: 30px;
}

// .custom-table-mobile thead th {
//   .first {
//     padding: 10px;
//     border: 1px solid $border-color;
//     text-align: center;
//   }
// }

.custom-table-mobile tbody tr {
  transition: background-color 0.5s;
  height: 60px;
}

// .custom-table-mobile tbody tr:nth-child(even) {
//   background-color: $bg-light;
// }

// .custom-table-mobile tbody tr:active {
//   background-color: $bg-hover;
//   cursor: pointer;
// }

.custom-table-mobile tbody td {
  padding: 5px;
  //border: 1px solid $border-color;
  text-align: center;
}

.custom-table-mobile tbody td span {
  //padding: 5px;
  text-align: center;
}

//--end custom-table mobile

.filter-input-mobile {
  padding: 5px;
  background-color: transparent;
  border: none;
  border-bottom: 1px solid $border-color;
  color: #eb3d5c;
  outline: none;
  font-size: 14px;
  font-style: italic;
  width: 100%;
  text-align: center;
  font-family: Chakra Petch;
  caret-color: #eb3d5c;
}

.label-text {
  display: flex;
  color: #ffffff;
  align-items: center;
}

.label-text-mobile {
  margin-top: 5px;
  display: flex;
  color: #ffffff;
  font-size: 12px;
}

.label-text-mobile-filter {
  display: flex;
  color: #ffffff;
  font-size: 12px;
  display: flex;
  flex-grow: 1;
  margin-bottom: 0.5rem;
}

.filter-input:focus {
  border-color: $border-focus;
  animation: pulse 1.5s infinite;
}

.sort-icon {
  margin-left: 5px;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 5rem;

  input[type="number"] {
    background-color: #0d8e77;
    margin-left: 10px;
    border: 1px solid #ccc;
    border-radius: 3px;
    text-align: center;
    height: 25px;
    width: 50px !important;
    color: white;
  }
}

.pagination > span {
  font-family: Chakra Petch !important;
  font-size: 12px !important;
  margin: 5px;
}

.pagination-button {
  background-color: #eb3d5c;
  clip-path: polygon(0 0, 45% 0, 100% 55%, 100% 100%, 55% 100%, 0 45%);
  border: none;
  cursor: pointer;
  font-size: 1.5rem;
  padding: 0.3rem 0.4rem;
  margin: 0 0.5rem 0 0.5rem;
  color: white;
  position: relative;
  width: 4rem;
  transition: all 0.5s ease-in;

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.pagination-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-left: 0.5rem;
}

.pagination-text-mobile {
  margin-top: 0.5rem;
}

.pagination-text-mobile > span {
  font-size: 14px !important;
  font-family: Chakra Petch !important;
}

.pagination-button-container {
  display: flex;
  width: 60%;
  justify-content: space-between;
  margin-bottom: 1rem;
  margin-top: 1rem;
}

.pagination-button > span {
  font-size: 16px !important;
}

.right-span:not(:disabled):hover {
  background-color: #0d8e77;
  transition: transform 0.5s ease-out; /* Modify transition property */
  transform: translateX(10%);
}

.left-span:not(:disabled):hover {
  background-color: #0d8e77;
  transition: transform 0.5s ease-out;
  transform: translateX(-10%);
}

.controls {
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
  align-items: center;
  font-family: Chakra Petch;
  font-size: 16px;
}

.controls-container {
  margin-left: 2rem !important;
  display: flex;
  justify-content: space-between;
}

.label-row {
  display: flex;
  align-items: end;
}

@media screen and (max-width: 768px) {
  .custom-table tbody tr {
    border: 1px solid #eee;
  }

  .controls {
    flex-direction: column;
    align-items: flex-start;
    justify-content: start;
    margin-bottom: 0.5rem;
  }

  .controls-container {
    flex-direction: column;
    align-items: start;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .controls-row {
    width: 100%;
    margin-top: 1rem;
    margin-bottom: 1rem;
  }

  .label-row {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: start;
  }

  .label-row > .label-text {
    margin-top: 1rem;
  }

  .label-text {
    width: 100%;
    justify-content: space-between;
    margin-left: 0;
    align-items: center;
  }

  .filter-input {
    width: 100%;
  }

  .slider {
    position: absolute !important;
    cursor: pointer !important;
    left: 25% !important;
    height: 20px !important;
    width: 40px !important;
    background-color: #7dc1b5 !important;
    transition: all 0.5s ease-in-out !important;
    //clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
  }

  .slider:before {
    position: absolute !important;
    content: "" !important;
    height: 100% !important;
    width: 50% !important;
    background-color: $custom-table-color !important;
    transition: all 0.5s ease-in-out !important;
    //clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
  }

  input:checked + .slider {
    background-color: #015949 !important;
  }

  input:checked + .slider:before {
    transform: translateX(100%);
  }

  .toggle-container {
    width: 25%;
    height: 50px;
    display: flex !important;
    flex-direction: column;
    align-items: center !important;
    justify-content: space-between !important;
  }

  .toggle-container:nth-child(3) {
    width: 50%;
  }

  .toggle-switch {
    position: relative;
    display: flex !important;
    width: 100% !important;
    height: 20px !important;
    //margin-left: 5px;
    align-items: center !important;
    justify-content: center !important;
    //margin-top: 10px;
  }

  .toggle-switch input {
    display: none !important;
  }

  .td-wrapper:nth-child(1) {
    justify-content: center !important;
  }

  .label-text-mobile-filter {
    width: 100%;
  }

  .pagination {
    margin-top: 3rem;
  }
  .image-thumbnail img:hover {
    transform: scale(1) !important;
    transition: all 0.7s ease-out;
  }
}

.hover-container {
  position: relative;
  display: flex;
}

.hover-text {
  display: none;
  position: absolute;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 3px;
  /* Adjust these values to position the tooltip as needed */
  left: 0;
  top: 20px;
  white-space: nowrap;
  z-index: 1;
  margin-left: 10px;
  font-size: 10px;
  color: white;
}

.fetch-data-mobile {
  display: flex;
  justify-content: center;
  width: 87%;
  background-color: transparent;
  border: 1px solid #14c2a3;
  border-radius: 4px;
  padding: 3px;
  white-space: nowrap;
  z-index: 1;
  margin-left: 20px;
  font-size: 12px;
  color: white;
  animation: fadeIn ease-in-out 3s;
  margin-bottom: 20px;
}

@keyframes fadeIn {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 0;
  }
}

.hover-container:hover .hover-text {
  display: block;
}

.icon {
  font-size: 16px; /* Adjust the size as needed */
}

.toggle-switch {
  position: relative;
  display: inline-block;
  width: 72px;
  height: 30px;
  margin-left: 5px;
}

.toggle-switch input {
  display: none !important;
}

.slider {
  position: absolute !important;
  cursor: pointer !important;
  width: 100%;
  height: 30px;
  left: 0;
  background-color: #7dc1b5 !important;
  transition: all 0.5s ease-in-out !important;
  border-radius: 0 !important; /* Remove rounded corners */
  // clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
}

.slider:before {
  position: absolute !important;
  content: "" !important;
  height: 100% !important;
  width: 50% !important;
  background-color: $custom-table-color !important;
  transition: all 0.5s ease-in-out !important;
  border-radius: 0 !important; /* Remove rounded corners */
  // clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
}

input:checked + .slider {
  background-color: #015949 !important;
}

input:checked + .slider:before {
  transform: translateX(100%);
}

.toggle-container {
  display: flex !important;
  align-items: center !important;
  justify-content: space-between;
}

.label-text.toggle-label {
  margin-left: 0;
  margin-right: 0.5rem;
}

.controls-row {
  display: flex;
  align-items: center;
  gap: 1rem; /* Adjust this value to change the spacing between the elements */
}

.center-align {
  text-align: center !important;
}

.hover-container {
  position: relative;
  display: inline-block;
}

.hover-text {
  display: none;
  position: absolute;
  background-color: transparent;
  border: 1px solid #ffffff;
  border-radius: 4px;
  padding: 3px;
  /* Adjust these values to position the tooltip as needed */
  left: 100%;
  top: -20px;
  white-space: nowrap;
  z-index: 1;
  margin-left: 10px;
  font-size: 10px;
  color: white;
}

.hover-container:hover .hover-text {
  display: block;
}

.icon {
  font-size: 16px; /* Adjust the size as needed */
}

.icon-mobile {
  font-size: 10px; /* Adjust the size as needed */
  color: white;
  //margin-left: 5px;
}

.icon-mobile-arrow {
  font-size: 10px; /* Adjust the size as needed */
  color: #ec98a9;
  margin-left: 5px;
}

.sale-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon {
  color: white;
}

.name-container {
  display: flex;
  align-items: center;
  position: relative;
  justify-content: space-around;
}

.td-wrapper {
  width: 33%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.td-wrapper:nth-child(1) {
  justify-content: end;
}

.show-image-icon {
  margin-left: 2px; /* Add some space between the name and the icon */
  color: white; /* Make the icon white */
  cursor: pointer;
}

.image-thumbnail img {
  max-width: 100%;
  max-height: 100%;
  border-radius: 10%;
  transition: all 0.7s ease-out;
}

.image-thumbnail img:hover {
  transform: scale(1.3);
  transition: all 0.7s ease-out;
}

.center-align {
  text-align: center;
}

.error-message {
  text-align: center;
  color: white;
  font-size: larger;
}

.header-container,
.row-container {
  display: flex;
}

.icon.show-image-icon.disabled {
  cursor: not-allowed;
  opacity: 0.5;
  pointer-events: none;
}

.icon.show-image-icon-pagination {
  font-size: 25px; /* Adjust the font-size value as needed */
}

@keyframes pulse {
  0% {
    border-color: $border-color;
  }
  50% {
    border-color: $border-focus;
  }
  100% {
    border-color: $border-color;
  }
}
// .header-cell,
// .cell {
//   flex: none;
// }

// .header-cell,
// .cell {
//   flex: 1;
// }
