.link-octagon {
  background-color: #eb3d5c;
  position: relative;
  display: flex !important;
  // clip-path: polygon(
  //   30% 0%,
  //   70% 0%,
  //   100% 30%,
  //   100% 70%,
  //   70% 100%,
  //   30% 100%,
  //   0% 70%,
  //   0% 30%
  // );
  clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
  width: 44px;
  height: 44px;
  justify-content: center;
  align-items: center;
  margin: 0 5px 0;
  transition: all 0.7s ease-in-out;
}

.link-octagon:hover {
  transition: all 0.7s ease-in-out;
  transform: scale(1.2);
}

@media screen and (max-width: 768px) {
  .link-octagon {
    background-color: #eb3d5c;
    position: relative;
    display: flex !important;
    // clip-path: polygon(
    //   30% 0%,
    //   70% 0%,
    //   100% 30%,
    //   100% 70%,
    //   70% 100%,
    //   30% 100%,
    //   0% 70%,
    //   0% 30%
    // );
    clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
    width: 30px;
    height: 30px;
    justify-content: center;
    align-items: center;
    margin: 0 5px 0;
    transition: all 0.7s ease-in-out;
  }
}
