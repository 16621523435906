.footer-container {
  display: flex;
  justify-content: start;
  align-items: center;
}

.footer-links {
  display: flex;
  justify-content: end;
  align-items: center;
}

@media all and (max-width: 768px) {
  .footer-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 0px;
    width: 40%;
  }
  .footer-links {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px !important;
  }
}
