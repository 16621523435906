.hover-container {
  position: relative;
  display: inline-block;
  width: 30px;
  height: 30px;
  text-align: center;
}

.hover-text {
  height: 2rem;
  font-size: 1rem;
  display: none;
  position: absolute;
  background-color: transparent;
  border: 1px solid #0d8e77;
  border-radius: 4px;
  padding: 3px;
  /* Adjust these values to position the tooltip as needed */
  left: 0;
  top: 34px;
  white-space: nowrap;
  z-index: 1;
  margin-left: 10px;
  color: white;
}

@media screen and (max-width: 768px) {
  .hover-text {
    top: 0;
    left: 20px;
  }

  .sale-button {
    margin: 0 0 0 5px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem !important;
    height: 1.5rem !important;
    background-color: #eb3d5c;
    clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
    transition: all 0.7s ease-in-out;
  }

  .info-button {
    margin: 0 0 0 5px !important;
    cursor: default;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 2.5rem !important;
    height: 1.5rem !important;
    background-color: #015949;
    clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
    transition: all 0.7s ease-in-out;
  }

  .sale-button:hover {
    transform: scale(1.1);
    transition: all 0.7s ease-in-out;
  }
}

.hover-container:hover .hover-text {
  display: block;
}

.icon-question {
  font-size: 16px !important; /* Adjust the size as needed */
  clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
  display: flex;
  justify-content: center;
  align-items: center;
}

.icon-question:hover {
  transform: rotate(360deg);
  transition: all 1s ease-in-out;
}

.icon {
  font-size: 16px; /* Adjust the size as needed */
  // clip-path: polygon(
  //   30% 0%,
  //   70% 0%,
  //   100% 30%,
  //   100% 70%,
  //   70% 100%,
  //   30% 100%,
  //   0% 70%,
  //   0% 30%
  // );
  display: flex;
  justify-content: center;
  align-items: center;
}

.info-span {
  background-color: #eb3d5c;
  cursor: default;
  height: 30px;
}

.sale-info-container {
  display: flex;
  justify-content: center;
  align-items: center;
}

.sale-button {
  margin: 0 0 0 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 2.2rem;
  background-color: #eb3d5c;
  clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
  transition: all 0.7s ease-in-out;
}

.info-button {
  margin: 0 0 0 10px;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 3.5rem;
  height: 2.2rem;
  background-color: #015949;
  clip-path: polygon(0 0, 70% 0, 100% 30%, 100% 100%, 30% 100%, 0 70%);
  transition: all 0.7s ease-in-out;
}

.sale-button:hover {
  transform: scale(1.1);
  transition: all 0.7s ease-in-out;
}

.icon {
  color: white;
  //margin-left: 2px; /* Add some space between the price and the icon */
}

.show-image-icon {
  margin-left: 2px; /* Add some space between the name and the icon */
  color: white; /* Make the icon white */
  cursor: pointer;
}

.image-thumbnail {
  //padding: 4px;
  // box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.center-align {
  text-align: center;
}

.error-message {
  text-align: center;
  color: white;
  font-size: larger;
}

@keyframes fadeInOut {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.5;
  }
  100% {
    opacity: 1;
  }
}

.loading-div-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; /* or set to the height you want */
}

.loading-div {
  animation: fadeInOut 2s infinite;
  font-family: Chakra Petch;
  font-size: 25px;
  color: whitesmoke;
}
