.message {
    .modal-content {
        border: 1px solid #DEA30B;
        background: rgba(0, 0, 0, 0.7);
        border-radius: 20px; 
        box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    }

    .modal-header {
        border-bottom: 0px;
        padding-bottom: 0px;
    }

    .modal-body {
        color: #FFFFFF;
    }

    .modal-footer {
        border-top: 0px;
    }

    a.button-confirm {
        background: #1EC4CE ;
        border-radius: 5px;
        width: 336px;
        height: 49px;
        padding: 8px 0px;
    }

    h5 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 30px;
    }
    h6 {
        font-family: 'Open Sans';
        font-style: normal;
        font-weight: 300;
        font-size: 18px;
        line-height: 20px;
    }
}

.danger {
    .modal-content {
        border: 1px solid red;
    }
}
