@media all and (max-width: 768px) {
  .navbar-style {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    font-size: 12px !important;
  }
  
  .button-outline {
    border: none !important;
  }
}


